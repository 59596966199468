module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132849357-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Noir Fatale Dark Electronics","short_name":"Noir Fatale","start_url":"/","background_color":"#2d1d04","theme_color":"#2d1d04","display":"minimal-ui","icon":"src/images/moth.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1040dd0d421bc50f84a8b2047102ac91"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
